<template>
    <user-dashboard-layout>
        <v-snackbar
            v-model="snackbar"
            fixed
            right
            top
            color="blue"
        >
            {{ textSnackbar }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    {{ $t('general.close') }}
                </v-btn>
            </template>
        </v-snackbar>
        <v-card :loading="loading">
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ order.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'dashboard.orders.index'}">{{ $t('general.back') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col class="py-0">
                            <v-chip class="mr-2 mt-0 text--disabled" small>{{ $t('general.order-id') }}: {{ order.id }}</v-chip>
                            <v-card-subtitle class="caption mb-4 px-0 text--disabled">{{ $t('general.created-order') }} : {{ (new Date(order.created_at)).toLocaleDateString() }}</v-card-subtitle>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.name')" :placeholder="$t('general.name')" outlined v-model="order.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.email')" :placeholder="$t('general.email')" outlined
                                          v-model="order.email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.phone')" :placeholder="$t('general.phone')" outlined
                                          v-model="order.phone"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-textarea :label="$t('general.order')" :placeholder="$t('general.order')" outlined
                                        v-model="order.details"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0 col-6">
                            <v-text-field :label="$t('general.delivery-date')" :placeholder="$t('general.delivery-date')" outlined type="date"
                                          v-model="order.date"></v-text-field>
                        </v-col>
                        <v-col class="py-0 col-6">
                            <v-text-field :label="$t('general.delivery-time')" :placeholder="$t('general.delivery-time')" outlined
                                          type="time" step="60000"
                                          v-model="order.working_hour.started_at"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0">
                            <v-text-field :label="$t('general.status')" :placeholder="$t('general.status')" outlined
                                          v-model="order.status"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="max-width-200">
                            <v-btn block color="green" class="text--white" @click="handleSaveOrder">{{ $t('general.save-changes') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import Order from "../../../models/Order";

export default {
    name: "orders-show",
    components: {UserDashboardLayout},
    data: function () {
        return {
            order: {},
            loading: false,
            snackbar: false,
            textSnackbar: `Updated`,
        }
    },
    methods: {
        async handleSaveOrder() {
            let _order = new Order(this.order)
            _order = await _order.save()
            this.$emit('update', _order)
            this.snackbar = true
        }
    },
    async mounted() {
        this.loading = true
        if (this.$route.params.id) {
            this.order = await Order.find(this.$route.params.id)
            const order_id = this.$route.params.id
            await Order.with(['schedule']).find(order_id)
        }
        console.log("%cid Заказа:" + this.$route.params.id, "border-radius: 20px; padding: 2px 4px; color: #fff; background-color: green")
        this.loading = false
    },
}
</script>

<style scoped>
.text--white {
    color: white;
}

.max-width-200 {
    max-width: 200px;
}
</style>

